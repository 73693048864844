import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

export default function Pagination({
  perPage = 15,
  total,
  paginate,
  current,
}) {
  const pageNumbers = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex flex-row items-center justify-between mx-auto w-full lg:max-w-xl mt-5">
      <button
        className={`border text-xl rounded-full w-12 h-12 lg:w-16 lg:h-16 hover:opacity-75 active:shadow-none ${current <= 1 ? 'border-gray-200 text-gray-200 pointer-events-none' : 'border-primary-500 text-primary-500 cursor-pointer'}`}
        disabled={current <= 1}
        onClick={() => paginate(current - 1)}
      >
        <ChevronLeftIcon className="mx-auto h-1/2" />
      </button>
      <span className="text-xl text-zz-blue-500">
        Seite
        {' '}
        {current}
        {' '}
        von
        {' '}
        {Math.ceil(total / perPage)}
      </span>
      <button
        disabled={current >= Math.ceil(total / perPage)}
        onClick={() => paginate(current + 1)}
        className={`border text-xl rounded-full w-12 h-12 lg:w-16 lg:h-16 hover:opacity-75 active:shadow-none ${current >= Math.ceil(total / perPage) ? 'border-gray-200 text-gray-200 pointer-events-none' : 'border-primary-500 text-primary-500 cursor-pointer'}`}
      >
        <ChevronRightIcon className="mx-auto h-1/2" />
      </button>
    </div>
  );
}
