import Switch from 'react-switch';

import { scroller } from 'react-scroll';
import Image from 'next/image';
import Button from '../elements/button';
import SearchIcon from '../../../public/assets/icons/boat.svg';
import TipsIcon from '../../../public/assets/icons/tips.svg';
import ContentHeadline from './headline';
import parse from '../helper/image';

const defaultImagePath = '/assets/images/hero';
const defaultImage = `${defaultImagePath}/2x.jpg`;

export default function Hero({
  pretitle = '',
  title,
  teaser,
  href,
  label,
  checked = false,
  setChecked = () => {
  },
  photoUrl = defaultImage,
  switchSearchLabel = 'Boote suchen & finden',
  switchLabel = 'Revierinformationen',
  toggle = true,
  regionalStatus = '',
  after = '',
  before = '',
}) {
  const setCheckedFunc = (v) => {
    setChecked(v);

    if (v) {
      setTimeout(() => {
        scroller.scrollTo('breadcrumbs', {
          duration: 1500,
          delay: 100,
          smooth: true,
        });
      }, 600);
    }
  };

  const [image1x, image2x] = photoUrl && photoUrl !== defaultImage
    ? [parse(photoUrl.publicUrl, { w: 750 }), parse(photoUrl.publicUrl, { w: 1500 })]
    : [`${defaultImagePath}/1x.jpg`, `${defaultImagePath}/2x.jpg`];

  // noinspection CssUnusedSymbol
  return (
    <div
      className="bg-gray-200 mt-header relative grid grid-cols-1 md:grid-cols-2"
    >
      <style jsx>
        {`
            #hero-background-image {
              background-image: url(${image2x});
              background-image: image-set(
                url(${image1x}) 1x,
                url(${image2x}) 2x);
            }
        `}
      </style>
      <div
        id="hero-background-image"
        className="relative bg-center bg-cover bg-no-repeat h-32 md:h-full"
      >
        <svg
          className="w-full absolute bottom-0 md:hidden"
          viewBox="0 0 100 35"
        >
          <path
            className="path fill-gray-200"
            d="M00 30 Q 50 40 100 30 L100 60 L0 60 Z"
            fill="#F3F2F2"
          />
        </svg>
      </div>
      <div
        className="py-2 pb-12 md:py-8 px-6 md:px-10 w-full max-w-2xl"
      >
        <div className="font-bold lg:text-sm text-xs mb-3">
          {pretitle}
        </div>
        <ContentHeadline tag={1} after={after} before={before}>
          <span>{title}</span>
        </ContentHeadline>

        <div className="lg:text-base text-sm max-w-lg lg:pr-10 pb-8" dangerouslySetInnerHTML={{ __html: teaser }} />
        {label && (
          <Button href={href}>{label}</Button>
        )}

        {toggle && (
          <div className="flex flex-row">
            <div>
              <Switch
                checked={checked}
                onChange={setCheckedFunc}
                handleDiameter={50}
                offColor="#f8f8f8"
                onColor="#292984"
                offHandleColor="#FBB900"
                onHandleColor="#FBB900"
                height={40}
                width={100}
                borderRadius={30}
                activeBoxShadow="0px 0px 1px 2px #fffc35"
                uncheckedIcon={(
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      marginRight: '-.8rem',
                    }}
                  >

                    <TipsIcon height={20} />
                  </div>
                )}
                checkedIcon={(
                  <div
                    style={{
                      display: 'flex',
                      color: '#fff',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      marginLeft: '-.8rem',
                    }}
                  >
                    <SearchIcon height={20} />
                  </div>
                )}
                uncheckedHandleIcon={(
                  <div
                    style={{
                      display: 'flex',
                      color: '#000',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <SearchIcon height={20} />
                  </div>
                )}
                checkedHandleIcon={(
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <TipsIcon height={20} />
                  </div>
                )}
              />

              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 44.54" className="-mt-1 h-12 ml-6">
                <polyline
                  points="1.5 0 1.5 38.27 16.5 38.27"
                  fill="none"
                  stroke="#FBB900"
                  strokeWidth="3"
                />
                <polyline
                  points="55.0 0 55.0 12.2 70 12.2"
                  fill="none"
                  stroke="#FBB900"
                  strokeWidth="3"
                />
                <text
                  transform="translate(80 17.22)"
                  className={`transition-all ${checked ? 'font-semibold fill-primary' : 'fill-black'}`}
                >
                  {switchLabel}
                </text>
                <text
                  transform="translate(25.37 43.54)"
                  className={`transition-all ${!checked ? 'font-semibold fill-primary' : 'fill-black'}`}
                  dangerouslySetInnerHTML={{ __html: switchSearchLabel }}
                />

              </svg>
            </div>
            {regionalStatus && (
              <div className="z-50 ml-auto hover:scale-125 transition">
                <Image
                  src={`/assets/icons/partner/${regionalStatus}.svg`}
                  alt={`${regionalStatus} Partner`}
                  width={110}
                  height={110}
                />
              </div>
            )}
          </div>
        )}

      </div>
      <svg
        className="w-full absolute bottom-0 pointer-events-none"
        viewBox="0 0 100 35"
      >
        <path
          className="path sc-correos-cdk-simple-header"
          d="M00 30 Q 50 40 100 30 L100 60 L0 60 Z"
          fill="#fff"
        />
      </svg>
    </div>
  );
}
